function retry(store, method, callback) {
  const loading = store.rootGetters.checkLoading("SOCKET", method)
  if(loading){
    callback()
    setTimeout(() => {
      retry(store, method, callback)
    }, 2500)
  }
}

export default {
  SEND_route(store, payload) {
    socketSend(this, store,
      {
        type: "route",
        route: payload.path,
        entityName: payload.meta.entityName
      }
    )
  },
  SEND_openUserDashboard(store) {
    socketSend(this, store, { type: "openUserDashboard" })
  },
  SEND_closeUserDashboard(store) {
    socketSend(this, store, { type: "closeUserDashboard" })
  },
  SEND_lists(store, payload) {
    const lists = payload.route.meta.lists || []
    let filter = payload.filter

    lists.forEach(list => {
      filter = {
        ...filter,
        ...store.rootGetters.getFilterList(list)
      }
    })

    Object.keys(filter).forEach((key) => {
      if (!filter[key] || filter[key] === "") delete filter[key];
    });

    socketSend(this, store, { 
      type: "lists",
      lists,
      filter,
      archiv: payload.archiv
    })
    if(lists){
      lists.forEach((list) => {
        store.commit("addLoading", {
          skeleton: false,
          method: "SOCKET",
          route: "lists_" + list.name,
        }, {root: true})

        setTimeout(() => {
          retry(store, "lists_" + list.name, () => {
            socketSend(this, store, { 
              type: "lists",
              lists,
              filter,
              archiv: payload.archiv
            })
          })
        }, 2500)
      })
    }
  },
  SEND_ticket(store, payload) {
    socketSend(this, store, { 
      type: "ticket",
      ticket: payload,
    })
  },
  SEND_anonStatus(store) {
    socketSend(this, store, { 
      type: "anonStatus"
    })
  },
  SEND_activ(store, payload) {
    socketSend(this, store, { 
      type: "activ",
      ...payload
    })
  },
  SEND_save(store, payload) {
    socketSend(this, store, { 
      type: "save",
      ...payload.meta
    })
  },
  SEND_motd(store) {
    socketSend(this, store, { 
      type: "motd",
    })
  },
  SEND_analyzeProgress(store, payload) {
    socketSend(this, store, { 
      type: "analyzeProgress",
      ...payload
    })
  },


  RECIEVE_openBy(store, payload) {
    store.commit("setOpenBy", payload)
  },
  RECIEVE_openByAll(store, payload) {
    Object.keys(payload.openBy).forEach(route => {
      store.commit("setOpenBy", {
        route,
        openBy: payload.openBy[route]
      })
    })
  },
  RECIEVE_count(store, payload) {
    store.commit("setCount", payload)
    store.commit("removeLoading", {
      skeleton: false,
      method: "SOCKET",
      route: "lists_" + payload.list,
    }, {root: true})
  },
  RECIEVE_ticket(store, { ticket }) {
    store.commit("tickets/addTicket", ticket, {root: true})
  },
  RECIEVE_userDashboard(store, { dashboard }) {
    store.commit("setUserDashboard", { dashboard })
  },
  RECIEVE_anonStatus(store, payload) {
    store.commit("setAnonStatus", payload)
  },
  RECIEVE_motd(store) {
    store.dispatch("READ_Motd", null, {root: true})
  },
  RECIEVE_analyzeProgress(store, payload) {
    store.commit("setAnalyzeProgress", payload)
  },
};

function socketSend(self, store, payload){
  const accessToken = store.rootGetters.getAccessToken

  if (!self.$socket || self.$socket.readyState !== 1){
    setTimeout(() => {
      socketSend(self, store, payload)
    }, 2500)
    return
  }

  self.$socket.send(
    JSON.stringify({
      ...payload,
      accessToken
    })
  )
}