export default {
  shortOpinionsList: localStorage
    .getItem("filterHeaders.shortOpinionsList")
    ?.split(",") || [
    "sendDate",
    "policyHolder.gender",
    "policyHolder.birthDate",
    "expert",
    "supervisor",
    "treatments",
    "conclusion.name",
    "testOrders",
    "servicePackage.name",
    "pricePackage.name",
    "duration",
    "documentPageCount",
    "status",
    "workingTime"
  ],
  shortOpinionOverviewList: localStorage
    .getItem("filterHeaders.shortOpinionOverviewList")
    ?.split(",") || [
    "sendDate",
    "policyHolder.gender",
    "policyHolder.birthDate",
    "expert",
    "supervisor",
    "treatments",
    "conclusion.name",
    "testOrders",
    "servicePackage.name",
    "pricePackage.name",
    "createdAt",
    "dueDate",
    "finishDate",
    "policyHolder.insuranceNumber",
    "costBearer.name",
    "contactPerson",
    "goae",
    "preCaseReferenceNumber",
    "express",
    "reclaim",
    "cancellation",
    "specialFee",
    "duration",
    "documentPageCount",
    "status",
    "workingTime"
  ],
  secondOpinionsList: localStorage
    .getItem("filterHeaders.secondOpinionsList")
    ?.split(",") || [],
  secondOpinionOverviewList: localStorage
    .getItem("filterHeaders.secondOpinionOverviewList")
    ?.split(",") || [
      "createdAt",
      "dueDate",
      "sendDate",
      "finishDate",
      "patient.insuranceNumber",
      "patient.gender",
      "patient.birthDate",
      "costBearer.name",
      "express",
      "reclaim",
      "cancellation",
      "preCaseReferenceNumber",
      "inSystem"
    ],
  goaesList: localStorage
    .getItem("filterHeaders.goaesList")
    ?.split(",") || [
    "sendDate",
    "policyHolder.gender",
    "policyHolder.birthDate",
    "expert",
    "supervisor",
    "treatments",
    "conclusion.name",
    "testOrders",
    "servicePackage.name",
    "pricePackage.name",
    "duration",
    "documentPageCount",
    "insuranceRate",
    "status",
    "workingTime"
  ],
  goaesOverviewList: localStorage
    .getItem("filterHeaders.goaesOverviewList")
    ?.split(",") || [
    "sendDate",
    "policyHolder.gender",
    "policyHolder.birthDate",
    "expert",
    "supervisor",
    "treatments",
    "conclusion.name",
    "testOrders",
    "servicePackage.name",
    "pricePackage.name",
    "createdAt",
    "dueDate",
    "finishDate",
    "policyHolder.insuranceNumber",
    "costBearer.name",
    "contactPerson",
    "goae",
    "preCaseReferenceNumber",
    "express",
    "reclaim",
    "cancellation",
    "specialFee",
    "duration",
    "documentPageCount",
    "insuranceRate",
    "proceed",
    "status",
    "workingTime"
  ],
  evaluation: localStorage
    .getItem("filterHeaders.evaluation")
    ?.split(",") || [
    "policyHolder.insuranceNumber",
    "policyHolder.gender",
    "policyHolder.birthDate",
    "supervisor",
    "createdAt",
    "dueDate",
    "finishDate",
    "contactPerson",
    "supervisor",
    "note",
    "express",
    "reclaim",
    "preCaseReferenceNumber",
    "goae",
    "shortOpinion",
    "sendDate",
    "cancellation",
    "specialFee",
    "checkDueDate",
    "openBy",
    "doneByCb",
    "workingTime"
  ],
  evaluation_summary: localStorage.getItem("filterHeaders.evaluation_summary")?.split(",") || [
    "conclusions",
    "directCount",
    "contradictionsCount"
  ],
  evaluation_summary_subject: localStorage.getItem("filterHeaders.evaluation_summary")?.split(",") || [
    "conclusions"
  ]
}
